import { useEffect } from 'react';
import { Image } from 'semantic-ui-react';

import { PmLegalTermsFooter } from 'containers/shared/footers/pm_legal_terms_footer';
import { UnauthenticatedHeader } from 'containers/shared/headers/unauthenticated_header';
import { PageNames, UnauthenticatedTitles } from 'containers/shared/headers/unauthenticated_titles';
import LpLink from 'containers/shared/lp_link';
import { FrontloadDataProps } from 'features/authentication/hooks/use_frontload_data';
import useHashParams from 'hooks/use_hash_params';
import useQueryParams from 'hooks/use_query_params';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { useUrlQueryParamSearch } from 'hooks/use_url_query_param_search';
import { frontend } from 'lib/urls';
import circleCheckIcon from 'static/img/tabler-icon-circle-check.svg';

import { LpLoginForm } from './login_form';

import './index.scss';

export const LoginPageV2 = ({
  frontloadData,
}: {
  frontloadData: ({ organizations, providedOrgId, providedWsId }: FrontloadDataProps) => void;
}) => {
  const isPasswordResetLogin = useUrlQueryParamSearch('reset') === 'true';

  const { from } = useQueryParams();
  const hash = useHashParams();

  let redirectLocation = from;
  if (Object.keys(hash).length) {
    redirectLocation += '#';
    Object.keys(hash).forEach((h) => {
      redirectLocation += `${h}=${hash[h]}&`;
    });
    redirectLocation = redirectLocation?.replace(/&$/, '');
  }

  const [redirectLocationState, setRedirectLocationState] = useSessionState(
    redirectLocation,
    SessionStorageKey.RedirectLocation,
  );

  useEffect(() => {
    setRedirectLocationState(redirectLocation);
  }, [redirectLocation, setRedirectLocationState]);

  return (
    <div className="pm-login">
      <UnauthenticatedHeader className="pm-login__header" />
      <div className={`pm-login__content  ${isPasswordResetLogin ? ' pm-login__content--password-reset' : ''}`}>
        {isPasswordResetLogin ? (
          <>
            <h3 className="password-reset__title">
              A recovery link has been sent to the email you provided. Please check your inbox.
            </h3>
            <Image src={circleCheckIcon} className="password-reset__icon" />
            <LpLink className="password-reset__sign-in-link" to={frontend.login.url({})}>
              Return to Sign In
            </LpLink>
          </>
        ) : (
          <>
            <UnauthenticatedTitles pageName={PageNames.LOGIN} />
            <LpLoginForm frontloadData={frontloadData} redirectLocation={redirectLocationState} />
          </>
        )}
      </div>
      {!isPasswordResetLogin && <PmLegalTermsFooter className="pm-signup__footer" />}
    </div>
  );
};
