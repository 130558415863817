import { Button } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { SyncProjectJobErrorType } from 'daos/external_integration_enums';
import { ExternalIntegrationSyncErrorOverview, SyncType } from 'daos/model_types';
import { PushTasksToJiraError } from 'daos/types';
import { PushItemsToJiraErrorByItemId } from 'features/common/data_grid/types';
import { JiraTabKey } from 'features/jira_project/modal/types';
import { useSyncErrorModalContext } from 'features/ppp/project/jira_controls/sync_error_modal/sync_error_modal_context';
import { JiraSyncPushError, SyncErrorModalType } from 'features/ppp/project/jira_controls/sync_error_modal/types';
import { pluralize } from 'lib/helpers';
import { ProductName } from 'lib/use_product_name';

export const isPushItemsToJiraError = (
  error: ExternalIntegrationSyncErrorOverview | PushTasksToJiraError,
): error is PushTasksToJiraError => {
  return (error as PushTasksToJiraError).errorDetails !== undefined;
};

export const getTotalSyncErrors = (
  syncErrorsGrouped: ReadonlyArray<ExternalIntegrationSyncErrorOverview | PushTasksToJiraError>,
) => {
  const uniqueErrorTypes = new Set<string>();

  return syncErrorsGrouped.reduce((totalErrors, error) => {
    if (isPushItemsToJiraError(error)) {
      if (!uniqueErrorTypes.has(error.errorType)) {
        uniqueErrorTypes.add(error.errorType);
        return totalErrors + 1;
      }
    }
    if ('errors' in error) {
      return totalErrors + error.errors.length;
    }
    return totalErrors;
  }, 0);
};

export const getSyncErrorsModalHeaderText = (
  isFatal: boolean,
  totalErrors: number,
  syncErrorType: SyncErrorModalType,
): string => {
  if (syncErrorType === SyncErrorModalType.PushTasksToJiraError) {
    return 'Sync Error';
  }
  if (isFatal) {
    return 'Import Error';
  }

  const alertSuffix = totalErrors > 1 ? 's' : '';
  return `${totalErrors} Alert${alertSuffix} Found`;
};

export const getLastSyncedText = (syncType?: SyncType) => {
  switch (syncType) {
    case SyncType.FULL:
      return 'Last Full Sync';
    case SyncType.PULL:
      return 'Last Sync from Jira';
    default:
      return 'Last Sync';
  }
};

export const getSyncErrorModalSubtext = (totalErrors: number, syncErrorType: SyncErrorModalType): string => {
  if (syncErrorType === SyncErrorModalType.PushTasksToJiraError) {
    return totalErrors > 1
      ? 'The following errors were encountered while pushing to Jira that require your attention'
      : 'An error was encountered while pushing to Jira that requires your attention';
  }

  return totalErrors > 1
    ? 'Several issues occurred when syncing that need your attention'
    : 'An issue occurred when syncing that needs your attention';
};

export const FatalErrorSubtext = ({
  syncError,
  onClose,
}: {
  syncError: SyncProjectJobErrorType;
  onClose: () => void;
}) => {
  const { setEditModalOpen, packageId } = useSyncErrorModalContext();

  switch (syncError) {
    case SyncProjectJobErrorType.JqlFilterParse:
      return (
        <>
          <p>
            Jira failed to parse the filters. Click{' '}
            <LpLink
              className="sync-error-modal__content-subRow-push-error-content-button"
              onClick={() => {
                onClose();
                setEditModalOpen(true);
              }}
              to={`?packageId=${packageId}#panelSection=${JiraTabKey.ProjectAndIssuesFilter}`}
            >
              here
            </LpLink>{' '}
            to update your filters.
          </p>
        </>
      );
    default:
      return (
        <>
          We are unable to import your Jira issues at this time. Please speak with an admin to resolve this error and
          try again.
        </>
      );
  }
};

const getDefaultErrorMessage = (syncError: SyncProjectJobErrorType) => {
  switch (syncError) {
    case SyncProjectJobErrorType.ProjectNotFound:
      return 'The configured project was not found';
    case SyncProjectJobErrorType.InvalidAuthentication:
      return 'The auth credentials are invalid';
    case SyncProjectJobErrorType.OverallSync:
      return 'An unhandled error occurred during sync';
    case SyncProjectJobErrorType.ResourceLimitExceeded:
      return 'The resource limit would be hit';
    case SyncProjectJobErrorType.JqlFilterParse:
      return 'An issue filter is invalid';
    case SyncProjectJobErrorType.SetCustomFieldValue:
      return 'Unable to set a custom field value';
    case SyncProjectJobErrorType.ConfigureStatuses:
      return 'Unable to configure statuses';
    case SyncProjectJobErrorType.CreateAssignment:
      return 'Failed to create an assignment';
    case SyncProjectJobErrorType.CreateTask:
      return 'Failed to create a task';
    case SyncProjectJobErrorType.ConfigureCustomField:
      return 'Failed to configure a custom field';
    case SyncProjectJobErrorType.ConfigureIssueType:
      return 'Failed to configure an issue type';
    case SyncProjectJobErrorType.ConfigureIssuePriority:
      return 'Failed to configure a priority';
    case SyncProjectJobErrorType.ItemIssueSync:
      return 'The sync failed for an individual item';
    case SyncProjectJobErrorType.UpdateTask:
      return 'Failed to update task';
    case SyncProjectJobErrorType.UpdateJiraStatus:
      return 'Failed to update status in Jira';
    case SyncProjectJobErrorType.ProjectUserSync:
      return 'Failed to sync project users';
    case SyncProjectJobErrorType.UnlinkedItem:
      return 'Item no longer fits the filter criteria';
    case SyncProjectJobErrorType.SubTaskIssueSync:
      return 'Failed to pull subtask on the individual item';
    case SyncProjectJobErrorType.ItemIssuePush:
      return 'Failed to push item to Jira';
    case SyncProjectJobErrorType.UpdateAssignment:
      return 'Failed to update assignment';
    case SyncProjectJobErrorType.DuplicateTask:
      return 'Issue already exists under a different project';
    case SyncProjectJobErrorType.PriorityNotFoundInJira:
      return 'Priority not settable in Jira';
    case SyncProjectJobErrorType.IssueRankPush:
      return 'Failed to push issue rank in Jira';
    case SyncProjectJobErrorType.FieldNotSettableInJira:
      return 'A field was not settable in Jira';
    case SyncProjectJobErrorType.WorklogSyncCostCodeError:
      return 'Failed to import time entries. Cost code is required';
    case SyncProjectJobErrorType.WorklogSyncError:
      return 'Failed to import time entries';
    case SyncProjectJobErrorType.AssigneeNotFoundInJira:
      return 'Failed to set an assignee in Jira';
    case SyncProjectJobErrorType.ProjectLeadInactive:
      return 'Failed to set Project lead on the project';
    case SyncProjectJobErrorType.InvalidMappingFieldValue:
      return 'A field value was not settable in Jira';
    case SyncProjectJobErrorType.TargetStartAfterFinish:
      return 'Failed to set Target Start or Target Finish dates on below task';
    case SyncProjectJobErrorType.StoryPointFieldNotFound:
      return 'Failed to find the Story Point field in Jira';
    case SyncProjectJobErrorType.MandatoryFieldValueUnset:
      return 'Jira Required Fields Not Set';
    case SyncProjectJobErrorType.MandatoryFieldUnmapped:
      return 'Jira Required Fields Not Mapped';
    case SyncProjectJobErrorType.CreateIssuePermissionMissing:
      return 'You do not have permission to create issues for the configured project in Jira';
    case SyncProjectJobErrorType.UnknownPushError:
      return 'Unhandled Error While Pushing Tasks to Jira';
    case SyncProjectJobErrorType.IssueTypeDoesNotExist:
      return 'Issue type does not exist in Jira';
    case SyncProjectJobErrorType.IssueDependencyPush:
      return 'Could not send dependencies to Jira';
    case SyncProjectJobErrorType.IssueTypeUnset:
      return 'Issue type has not been set';
    case SyncProjectJobErrorType.SprintIterationSync:
      return 'Could not sync iterations from Jira Sprint';
    case SyncProjectJobErrorType.IssueDescriptionPush:
      return 'Failed to push images from Notes to Jira description';
    case SyncProjectJobErrorType.IssueSprintPull:
      return 'Failed to pull sprint data for an item';
    case SyncProjectJobErrorType.PullChanges:
      return 'Failed to pull some changes for a task';
    case SyncProjectJobErrorType.IssuePushClosedSprint:
      return 'Unable to add task to a closed sprint';
  }
};

const getSyncProjectJobErrorGroupedMessage = ({
  syncError,
  totalErrors,
}: {
  syncError: SyncProjectJobErrorType;
  totalErrors: number;
}) => {
  switch (syncError) {
    case SyncProjectJobErrorType.SetCustomFieldValue:
      return `Unable to set a custom field value for ${totalErrors} tasks`;
    case SyncProjectJobErrorType.ConfigureCustomField:
      return `Failed to configure ${totalErrors} custom fields`;
    case SyncProjectJobErrorType.ConfigureIssueType:
      return `Failed to configure ${totalErrors} issue types`;
    case SyncProjectJobErrorType.ConfigureIssuePriority:
      return `Failed to configure ${totalErrors} priorities`;
    case SyncProjectJobErrorType.ConfigureStatuses:
      return `Failed to configure ${totalErrors} statuses`;
    case SyncProjectJobErrorType.ItemIssueSync:
      return `The sync failed for ${totalErrors} items`;
    case SyncProjectJobErrorType.CreateTask:
      return `Failed to create ${totalErrors} tasks`;
    case SyncProjectJobErrorType.UpdateTask:
      return `Failed to update ${totalErrors} tasks`;
    case SyncProjectJobErrorType.UpdateJiraStatus:
      return `Failed to update status in Jira for ${totalErrors} tasks`;
    case SyncProjectJobErrorType.CreateAssignment:
      return `Failed to create ${totalErrors} assignments`;
    case SyncProjectJobErrorType.UnlinkedItem:
      return `${totalErrors} tasks cannot be synced`;
    case SyncProjectJobErrorType.SubTaskIssueSync:
      return `${totalErrors} sub tasks/assignments cannot be synced`;
    case SyncProjectJobErrorType.DuplicateTask:
      return `${totalErrors} issues already exist as tasks on different projects`;
    case SyncProjectJobErrorType.ItemIssuePush:
      return `Failed to push ${totalErrors} items to Jira`;
    case SyncProjectJobErrorType.UpdateAssignment:
      return `Failed to update ${totalErrors} assignments`;
    case SyncProjectJobErrorType.PriorityNotFoundInJira:
      return `Failed to set priority in Jira for ${totalErrors} items`;
    case SyncProjectJobErrorType.FieldNotSettableInJira:
      return `${totalErrors} fields were not settable in Jira`;
    case SyncProjectJobErrorType.AssigneeNotFoundInJira:
      return `Failed to set ${totalErrors} assignees in Jira`;
    case SyncProjectJobErrorType.InvalidMappingFieldValue:
      return `${totalErrors} field values were not settable in Jira`;
    case SyncProjectJobErrorType.TargetStartAfterFinish:
      return `Failed to set Target Start or Target Finish dates on ${totalErrors} tasks`;
    case SyncProjectJobErrorType.StoryPointFieldNotFound:
      return `Failed to find the Story Point field on ${totalErrors} issues`;
    case SyncProjectJobErrorType.IssueDescriptionPush:
      return `Failed to push images from Notes to Jira description for ${totalErrors} tasks`;
    case SyncProjectJobErrorType.IssueSprintPull:
      return `Failed to pull sprint data for ${totalErrors} items`;
    case SyncProjectJobErrorType.PullChanges:
      return `Failed to pull some changes for ${totalErrors} tasks`;
    case SyncProjectJobErrorType.IssuePushClosedSprint:
      return `Unable to add tasks to a closed sprint`;
    case SyncProjectJobErrorType.ResourceLimitExceeded:
    case SyncProjectJobErrorType.JqlFilterParse:
    case SyncProjectJobErrorType.IssueRankPush:
    case SyncProjectJobErrorType.ProjectUserSync:
    case SyncProjectJobErrorType.OverallSync:
    case SyncProjectJobErrorType.ProjectNotFound:
    case SyncProjectJobErrorType.InvalidAuthentication:
    case SyncProjectJobErrorType.WorklogSyncError:
    case SyncProjectJobErrorType.CreateIssuePermissionMissing:
    case SyncProjectJobErrorType.UnknownPushError:
    case SyncProjectJobErrorType.IssueTypeDoesNotExist:
    case SyncProjectJobErrorType.IssueDependencyPush:
    case SyncProjectJobErrorType.IssueTypeUnset:
    case SyncProjectJobErrorType.SprintIterationSync:
      return getDefaultErrorMessage(syncError);
  }
};

export const getSyncProjectJobErrorMessage = ({
  syncError,
  totalErrors,
}: {
  syncError: SyncProjectJobErrorType;
  totalErrors: number;
}) => {
  if (
    syncError === SyncProjectJobErrorType.MandatoryFieldValueUnset ||
    syncError === SyncProjectJobErrorType.MandatoryFieldUnmapped
  ) {
    return getDefaultErrorMessage(syncError);
  }
  if (totalErrors === 1) {
    return getDefaultErrorMessage(syncError);
  }
  return getSyncProjectJobErrorGroupedMessage({ syncError, totalErrors });
};

export const getSyncProjectJobErrorSubHeading = (
  syncError: SyncProjectJobErrorType,
  totalErrors: number,
  setBulkEditModalOpen: (open: boolean) => void,
  setBulkEditModalType: (type: JiraSyncPushError) => void,
) => {
  switch (syncError) {
    case SyncProjectJobErrorType.OverallSync:
      return <>An unexpected error has occurred. Please try again later or contact support if the issue persists.</>;
    case SyncProjectJobErrorType.JqlFilterParse:
      return <>Please check your issue filter criteria is valid.</>;
    case SyncProjectJobErrorType.FieldNotSettableInJira:
      return <>Access to edit these fields is restricted. Contact your Jira administrator for assistance.</>;
    case SyncProjectJobErrorType.InvalidMappingFieldValue:
      return <>The selected values do not exist in Jira.</>;
    case SyncProjectJobErrorType.ConfigureCustomField:
      return (
        <>Unable to create or unarchive custom fields. Please try again later or contact support if issue persists.</>
      );
    case SyncProjectJobErrorType.ConfigureIssueType:
      return <>Failed to create issue types. Please try again later or contact support if issue persists.</>;
    case SyncProjectJobErrorType.ConfigureIssuePriority:
      return <>Failed to create priorities. Please try again later or contact support if issue persists.</>;
    case SyncProjectJobErrorType.ConfigureStatuses:
      return (
        <>
          Failed to create task statuses in <ProductName />. Please try again later or contact support if issue
          persists.
        </>
      );
    case SyncProjectJobErrorType.ProjectNotFound:
      return <>It may have been archived, deleted or access permissions have changed</>;
    case SyncProjectJobErrorType.InvalidAuthentication:
      return <>Please check your credentials, re-authenticate and then try again. Contact support if issue persists.</>;
    case SyncProjectJobErrorType.ResourceLimitExceeded:
      return (
        <>
          Insufficient Resources: An Org Admin must purchase additional resources to complete the sync. Contact support
          for help.
        </>
      );
    case SyncProjectJobErrorType.ProjectUserSync:
      return (
        <>
          Unable to sync assignable users from Jira in <ProductName />. Please try again later or contact support if
          issue persists.
        </>
      );
    case SyncProjectJobErrorType.UnlinkedItem:
      return (
        <>
          Tasks can no longer sync either because they have been deleted in Jira or they no longer match the configured
          filter criteria.
        </>
      );
    case SyncProjectJobErrorType.AssigneeNotFoundInJira:
      return <>The assigned user does not exist within Jira. Contact your Jira administrator for assistance.</>;
    case SyncProjectJobErrorType.CreateIssuePermissionMissing:
      return (
        <>
          The configured Jira user credentials do not have permission to create Issues. Contact your Jira administrator
          for assistance.
        </>
      );
    case SyncProjectJobErrorType.UnknownPushError:
      return <>Please try again later or contact support if the issue persists.</>;
    case SyncProjectJobErrorType.ProjectLeadInactive:
      return 'Selected Project Lead is inactive; update to an active user in Jira and try again.';
    case SyncProjectJobErrorType.TargetStartAfterFinish:
      return <>Ensure Jira tasks have a start date before the finish date and try again.</>;
    case SyncProjectJobErrorType.IssuePushClosedSprint:
      return (
        <>
          <strong>{totalErrors}</strong> {pluralize('task', totalErrors)} failed to be created because the sprint they
          are associated to in Jira are marked <strong>closed</strong>. Click{' '}
          <Button
            className="sync-error-modal__content-subRow-push-error-content-button"
            basic
            compact
            onClick={() => {
              setBulkEditModalType(SyncProjectJobErrorType.IssuePushClosedSprint);
              setBulkEditModalOpen(true);
            }}
          >
            <i>here</i>
          </Button>{' '}
          to move these tasks to an active iteration or contact your Jira administrator for assistance.
        </>
      );
    case SyncProjectJobErrorType.IssueTypeDoesNotExist:
      return <>Ensure the Issue Type selected is valid for the integrated Jira Project and try again.</>;
    case SyncProjectJobErrorType.SetCustomFieldValue:
    case SyncProjectJobErrorType.ItemIssueSync:
    case SyncProjectJobErrorType.CreateTask:
    case SyncProjectJobErrorType.UpdateTask:
    case SyncProjectJobErrorType.UpdateJiraStatus:
    case SyncProjectJobErrorType.CreateAssignment:
    case SyncProjectJobErrorType.SubTaskIssueSync:
    case SyncProjectJobErrorType.ItemIssuePush:
    case SyncProjectJobErrorType.UpdateAssignment:
    case SyncProjectJobErrorType.PriorityNotFoundInJira:
    case SyncProjectJobErrorType.IssueRankPush:
    case SyncProjectJobErrorType.StoryPointFieldNotFound:
    case SyncProjectJobErrorType.WorklogSyncError:
    case SyncProjectJobErrorType.IssueSprintPull:
    case SyncProjectJobErrorType.PullChanges:
      return <>Please try again later or contact support if issue persists.</>;
    default:
      return null;
  }
};

export const getPushTasksToJiraSubRowContent = ({
  errorCountOfItems,
  totalErrors,
  error,
  setEditModalOpen,
  setBulkEditModalOpen,
  packageId,
  setBulkEditModalType,
}: {
  errorCountOfItems: number;
  totalErrors: number;
  error: PushTasksToJiraError;
  setEditModalOpen: (show: boolean) => void;
  setBulkEditModalOpen: (show: boolean) => void;
  packageId: number;
  setBulkEditModalType: (type: JiraSyncPushError) => void;
}) => {
  const taskIsOrTasksAre = pluralize('task is', errorCountOfItems, 'tasks are');
  const itOrThem = pluralize('it', errorCountOfItems, 'them');
  const fieldOrFields = pluralize('field', totalErrors);
  const taskOrTasks = pluralize('task', errorCountOfItems);

  switch (error.errorType) {
    case SyncProjectJobErrorType.MandatoryFieldValueUnset:
      return (
        <>
          {errorCountOfItems} {taskIsOrTasksAre} missing required Jira fields, preventing {itOrThem} from syncing.{' '}
          {totalErrors} missing {fieldOrFields} must be filled to enable syncing. Click{' '}
          <Button
            className="sync-error-modal__content-subRow-push-error-content-button"
            basic
            compact
            onClick={() => {
              setBulkEditModalType(SyncProjectJobErrorType.MandatoryFieldValueUnset);
              setBulkEditModalOpen(true);
            }}
          >
            here
          </Button>{' '}
          to fill in the missing fields.
        </>
      );
    case SyncProjectJobErrorType.MandatoryFieldUnmapped:
      return (
        <>
          {errorCountOfItems} {taskIsOrTasksAre} missing required Jira fields, preventing {itOrThem} from syncing. Some
          fields aren&apos;t mapped. To resolve this, map the fields in the &apos;Edit Integration&apos; settings. Click{' '}
          <LpLink
            className="sync-error-modal__content-subRow-push-error-content-button"
            onClick={() => setEditModalOpen(true)}
            to={`?packageId=${packageId}#panelSection=${JiraTabKey.FieldMapping}`}
          >
            here
          </LpLink>{' '}
          to add mapping.
        </>
      );
    case SyncProjectJobErrorType.IssueTypeUnset:
      return (
        <>
          <p>
            {errorCountOfItems} {taskOrTasks} failed to be created because the issue type was not provided.
          </p>
          <p>
            Click{' '}
            <Button
              className="sync-error-modal__content-subRow-push-error-content-button"
              basic
              compact
              onClick={() => {
                setBulkEditModalType(SyncProjectJobErrorType.IssueTypeUnset);
                setBulkEditModalOpen(true);
              }}
            >
              here
            </Button>{' '}
            to set the issue type.
          </p>
        </>
      );
    default:
      return getSyncProjectJobErrorSubHeading(error.errorType, totalErrors, setEditModalOpen, setBulkEditModalType);
  }
};

export const getTotalPushItemsToJiraErrors = (pushItemsToJiraErrorObj: PushItemsToJiraErrorByItemId) =>
  Object.values(pushItemsToJiraErrorObj).reduce((totalErrors, errorObj) => {
    return totalErrors + errorObj.lpFieldId.length + errorObj.lpSystemFieldId.length;
  }, 0);
