import { Button } from 'semantic-ui-react';

import './index.scss';
import { MissingAccountTopBar } from 'features/authentication/unauthenticated/missing_account/top_bar';
import { checkCircleRegular, exclamationTriangleRegular, LpIcon } from 'features/common/lp_icon';
import { LpLogo } from 'features/shared/components/lp_logo';
import NewWindowLink from 'lib/display_helpers/url_links';
import { thirdPartyUrls } from 'lib/urls';

export const MissingAccountPage = () => {
  return (
    <div className="missing-account__container">
      <MissingAccountTopBar />
      <div className="missing-account__modal">
        <LpLogo className="missing-account__logo" />
        <div className="missing-account__header">
          <h2 className="missing-account__h2">Get Access to Portfolio Manager</h2>
          <h4 className="missing-account__h4">Use predictive scheduling to predict, adapt, and deliver.</h4>
        </div>
        <div className="missing-account__body">
          <div className="missing-account__box1">
            <div className="missing-account__box-icon">
              <LpIcon icon={checkCircleRegular} color="rgb(26, 138, 58)" />
            </div>
            <div>
              <h5 className="missing-account__h5">You are logged into Tempo Successfully</h5>
              <div className="missing-account__box-message">Your Tempo account [user@email.com] is authenticated.</div>
            </div>
          </div>
          <div className="missing-account__box2">
            <div className="missing-account__box-icon">
              <LpIcon icon={exclamationTriangleRegular} color="rgb(255, 165, 0)" />
            </div>
            <div>
              <h5 className="missing-account__h5">You don’t have Portfolio Manager Account</h5>
              <div className="missing-account__box-message">
                There’s no Portfolio Manager account linked to your Tempo credentials. To proceed, choose an option
                below
              </div>
            </div>
          </div>
        </div>
        <Button className="missing-account__button" onClick={() => window.open(thirdPartyUrls.portfolioManagerMktg)}>
          Try it Now
        </Button>
        <div className="missing-account__contact">
          Already have a Portfolio Manager account? Please contact your admin
        </div>
        <div className="missing-account__footer">
          Wonder what Portfolio Manager can do?{' '}
          <NewWindowLink className="missing-account__link" to={thirdPartyUrls.portfolioManagerMktg}>
            Learn more
          </NewWindowLink>
        </div>
      </div>
    </div>
  );
};
