import { castArray, compact, difference, isArray, isObject, map, memoize, omit, pick } from 'lodash';

import { EntityFilter, GroupsFilter, ItemFilter, WorkspaceUserFilter } from 'daos/filter_properties';
import { BodyObject, HttpMethod } from 'lib/api/types';
import { ReadonlyRecord } from 'lib/readonly_record';

import { ItemType, PackageStatus, StatusFilterGroups, SchedulingType, GroupType } from './enums';
import { FolderStatus } from './item_enums';
import { Model, ResourceId } from './model_types';

export enum FilterOperators {
  ContainsIgnoreCase = '=containsignorecase=',
  Eq = '==',
  Ge = '=ge=',
  Gt = '=gt=',
  In = '=in=',
  Le = '=le=',
  Lt = '=lt=',
  NotEq = '!=',
  Null = '=isnull=',
  Out = '=out=',
  StartsWithIgnoreCase = '=startswithignorecase=',
}

interface AttributesAndRelationships {
  readonly attributes: object;
  readonly relationships?: object;
}

interface DefineModelParams<T extends string, U extends string> {
  readonly apiType: U;
  readonly itemType?: string;
  readonly relationships?: ReadonlyArray<string>;
  readonly type: T;
  readonly mapAttributes?: (attrs: object) => object;
}

type DefineResourceId<S extends string, R extends Model<T>, T extends string> = { [K in S]: S } & {
  readonly resourceId: (id: number | ResourceId<R>) => R;
};

type GetRelationshipsAndAttributes = (attributes: { itemType?: string }, create: boolean) => AttributesAndRelationships;

type GetRelationshipsAndAttributesBuilder = (
  relationships: ReadonlyArray<string>,
  itemType?: string,
) => GetRelationshipsAndAttributes;

export type IndexModel<S extends Model<any> | Array<Model<any>>> = ReadonlyRecord<number, S>;
function resourceId<S extends string, R extends Model<S>>(type: S, id: number | ResourceId<R>): ResourceId<R> {
  return {
    id: isObject(id) ? (id as ResourceId<Model<S>>).id : (id as number),
    type,
  };
}

const defineResource = <T extends string, U extends string>(type: T, types: U): DefineResourceId<T, Model<U>, U> =>
  ({
    [type]: type,
    resourceId: memoize((id) =>
      isArray(id) ? map(id, (e) => resourceId<U, Model<U>>(types, e)) : resourceId<U, Model<U>>(types, id),
    ),
  }) as any as DefineResourceId<T, Model<U>, U>;

const getRelationshipsAndAttributes: GetRelationshipsAndAttributesBuilder =
  (relationships, itemType) => (attributes, create) => {
    return {
      attributes: {
        ...omit(attributes, relationships),
        itemType: create ? itemType || attributes.itemType : undefined,
      },
      relationships: pick(attributes, relationships),
    };
  };

export function defineModel<T extends string, U extends string>({
  apiType,
  itemType,
  relationships,
  type,
  mapAttributes = (e) => e,
}: DefineModelParams<T, U>) {
  const getAttrsAndRelationships: GetRelationshipsAndAttributes = relationships
    ? getRelationshipsAndAttributes(relationships, itemType)
    : (attributes, create) => ({
        attributes: {
          ...attributes,
          itemType: create ? itemType : undefined,
        },
      });

  const arrayBody = (id: number | undefined, attributes: ReadonlyArray<Partial<Model<U>>>) =>
    attributes.reduce(
      (acc: { body: Array<BodyObject> }, attr) => {
        acc.body.push({
          id,
          type: apiType,
          ...getAttrsAndRelationships(mapAttributes(attr), !id),
        });
        return acc;
      },
      { body: [] },
    );

  const body = (id: number | undefined, attributes: Partial<Model<U>> | Pick<Model<U>, any>) => ({
    body: {
      id,
      type: apiType,
      ...getAttrsAndRelationships(mapAttributes(attributes), !id),
    },
  });

  const createBody = (attributes: Partial<Model<U>> | Pick<Model<U>, any>) => ({
    body: {
      type: apiType,
      ...getAttrsAndRelationships(mapAttributes(attributes), true),
    },
    method: HttpMethod.POST,
  });

  const multipartCreateBody = (formData: FormData) => {
    formData.append('type', apiType);
    return {
      body: formData,
      method: HttpMethod.POST,
    };
  };

  const updateBody = (id: number, attributes: Partial<Model<U>> | Pick<Model<U>, any>) => ({
    body: {
      id,
      type: apiType,
      ...getAttrsAndRelationships(mapAttributes(attributes), false),
    },
    method: HttpMethod.PATCH,
  });

  const multipartUpdateBody = (formData: FormData) => {
    formData.append('type', apiType);
    return {
      body: formData,
      method: HttpMethod.PATCH,
    };
  };

  const deleteArrayBody = (ids: Array<number>) => {
    return ids.reduce(
      (acc: { body: Array<BodyObject>; method: HttpMethod.DELETE }, id) => {
        acc.body.push(resourceId(apiType, id));
        return acc;
      },
      { body: [], method: HttpMethod.DELETE },
    );
  };

  const deleteBody = (id: number) => ({
    body: resourceId(apiType, id),
    method: HttpMethod.DELETE,
  });

  return {
    arrayBody,
    body,
    createBody,
    multipartCreateBody,
    deleteArrayBody,
    deleteBody,
    getRelationshipsAndAttributes: getAttrsAndRelationships,
    relationships,
    resource: defineResource(type, apiType),
    updateBody,
    multipartUpdateBody,
  };
}

export const reduceIncludedOptions = (include: Array<string | false | undefined>) =>
  include.reduce((acc: Array<string>, includeOption) => {
    if (includeOption) {
      acc.push(includeOption);
    }
    return acc;
  }, []);

export const filterBooleanLiteral = (key: string, value: boolean): string => `${key}=booleanliteral=${value}`;

/**
 * This function comes from `rsql-builder` and uses their regex to determine
 * which characters to escape.
 *
 * GitHub: https://github.com/RomiC/rsql-builder/blob/master/src/escape-value.ts
 *
 * @param value value to escape characters and whitespace
 * @returns String
 */
export const escapeValue = (value: any): string => {
  const CHARS_TO_ESCAPE = /["'();,=!~<>\s]/;

  let val: string;

  if (typeof value !== 'string') {
    val = value.toString();
  } else {
    val = value;
  }

  if (CHARS_TO_ESCAPE.test(val) || val.length === 0) {
    const valWithEscapedQuotes = val.replace(/["]/g, '\\"'); // Replace all double-quotes with escaped double-quotes
    return `"${valWithEscapedQuotes}"`; //Wrap the string in double quotes
  } else {
    return val;
  }
};

export const rsqlFilter = (
  key: string,
  operation: string,
  value: number | string | boolean | Array<number | string>,
) => {
  let escapedValue: string;
  if (Array.isArray(value) && !value.length) {
    return filterBooleanLiteral(key, false);
  }

  if (Array.isArray(value)) {
    escapedValue = `(${value.map(escapeValue)})`;
  } else {
    escapedValue = escapeValue(value);
  }

  return `${key}${operation}${escapedValue}`;
};

export const filterAnd: (...args: Array<number | string>) => string = (...args) => {
  if (args.filter(Boolean).length === 0) {
    return '';
  }
  return `(${compact<number | string>(castArray(args)).join(';')})`;
};

export const filterOr: (...args: Array<string>) => string = (...args) => `(${compact(castArray(args)).join(',')})`;

export const filterEq: (key: string, value: number | string | boolean) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Eq, value);

export const filterNotEq: (key: string, value: number | string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.NotEq, value);

// Keeping this incase we need it in the future

export const filterGt: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Gt, value);

export const filterGte: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Ge, value);

export const filterLt: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Lt, value);

export const filterLte: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Le, value);

export const filterIn: (key: string, values: number | string | boolean | ReadonlyArray<number | string>) => string = (
  key,
  values,
) => (values instanceof Array ? rsqlFilter(key, FilterOperators.In, compact(values)) : filterEq(key, values));

export const filterNotIn: (key: string, values: number | string | ReadonlyArray<number | string>) => string = (
  key,
  values,
) => (values instanceof Array ? rsqlFilter(key, FilterOperators.Out, compact(values)) : filterNotEq(key, values));

export const filterNull: (key: string, value: boolean) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.Null, value);

export const filterContainsIgnoreCase: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.ContainsIgnoreCase, value);

export const filterWsUserCustomField = (value: string, customFieldIds: ReadonlyArray<string>): string =>
  customFieldIds.length
    ? filterIn(WorkspaceUserFilter.QuickCustomFieldValueFilter, [value].concat(customFieldIds))
    : '';

export const filterCustomField = (value: string, customFieldIds: ReadonlyArray<string>): string =>
  customFieldIds.length ? filterIn(ItemFilter.QuickCustomFieldValueFilter, [value].concat(customFieldIds)) : '';

export const filterChildCustomField = (value: string, customFieldIds: ReadonlyArray<string>): string =>
  customFieldIds.length ? filterIn(ItemFilter.ChildQuickCustomFieldValueFilter, [value].concat(customFieldIds)) : '';

export const filterParentCustomField = (value: string, customFieldIds: ReadonlyArray<string>): string =>
  customFieldIds.length ? filterIn(ItemFilter.ParentQuickCustomFieldValueFilter, [value].concat(customFieldIds)) : '';

export const filterStartsWithIgnoreCase: (key: string, value: string) => string = (key, value) =>
  rsqlFilter(key, FilterOperators.StartsWithIgnoreCase, value);

export const filterAncestorPackageStatus = (packageStatus: PackageStatus | Array<PackageStatus>): string =>
  filterIn(ItemFilter.AncestorPackageStatus, packageStatus);

export const filterChildOrganizationUser = (id: number | Array<number>): string =>
  filterIn(ItemFilter.ChildOrganizationUser, id);

export const filterChildOrganizationUserInGroupId = (id: number | Array<number>): string =>
  filterIn(ItemFilter.ChildOrganizationUserInGroupId, id);

export const filterFolderStatus = (status: FolderStatus | Array<FolderStatus>): string => {
  const statusIsArray = isArray(status);
  const singleStatus = statusIsArray ? status[0] : status;

  if (statusIsArray) {
    if (status.length === 0 || !singleStatus) {
      return filterBooleanLiteral(ItemFilter.FolderStatus, false);
    } else if (status.length === 1) {
      return filterEq(ItemFilter.FolderStatus, singleStatus);
    } else {
      return difference(Object.values(FolderStatus), status).length > 0
        ? filterIn(ItemFilter.FolderStatus, status)
        : '';
    }
  }

  return filterEq(ItemFilter.FolderStatus, status);
};

export const filterFolderStatusOrNull = (status: FolderStatus | Array<FolderStatus>): string => {
  const folderStatusFilter = filterFolderStatus(status);

  return folderStatusFilter ? filterOr(folderStatusFilter, filterNull(ItemFilter.FolderStatus, true)) : '';
};

export const filterHasAncestor = (id: number): string => filterEq(ItemFilter.HasAncestor, id);

export const filterHasAnyAncestor = (ids: ReadonlyArray<number>) => filterIn(ItemFilter.HasAnyAncestor, ids);

export const filterIsId = (id: number | string): string => filterEq(EntityFilter.Id, id);

export const filterIds = (ids: ReadonlyArray<number>): string => filterIn(EntityFilter.Id, ids);

export const filterItemIds = (ids: Array<number>): string => filterIn(EntityFilter.ItemId, ids);

export const filterGroupsAvailabilityIds = (ids: Array<number>): string => {
  return ids.length ? filterIn(GroupsFilter.GroupIds, ids) : '';
};

export const filterGroupType = (groupType: GroupType) => filterEq(GroupsFilter.GroupType, groupType);

export const filterItemType = (itemType: ItemType | Array<ItemType>): string => filterIn(ItemFilter.ItemType, itemType);

export const filterItemName = (value: string): string => filterContainsIgnoreCase(ItemFilter.Name, value);

export const filterItemCreatedByUserId = (userId: number) => filterEq(ItemFilter.CreatedByUserId, userId);

export const filterParentItemCreatedByUserId = (userId: number) => filterEq(ItemFilter.ParentCreatedByUserId, userId);

export const filterItemDoneDateIsNull = (value: boolean) => filterNull(ItemFilter.DoneDate, value);

export const filterPackageStatus = (packageStatus: PackageStatus | Array<PackageStatus>): string =>
  filterIn(ItemFilter.PackageStatus, packageStatus);

export const filterParentId = (ids: number | Array<number>): string => filterIn(ItemFilter.ParentId, ids);

export const filterGrandParentId = (id: number) => filterEq(ItemFilter.GrandParentId, id);

export const filterTaskStatusId = (id: number | ReadonlyArray<number>): string => {
  if (isArray(id)) {
    return id.length ? filterIn(ItemFilter.TaskStatusId, id) : '';
  }

  return filterEq(ItemFilter.TaskStatusId, id as number);
};

export const filterTaskStatusIdOrNull = (id: number | ReadonlyArray<number>): string => {
  const taskStatusIdFilter = filterTaskStatusId(id);

  return taskStatusIdFilter ? filterOr(taskStatusIdFilter, filterNull(ItemFilter.TaskStatusId, true)) : '';
};

export const filterParentTaskStatusGroup = (status: StatusFilterGroups): string =>
  status !== StatusFilterGroups.All ? filterEq(ItemFilter.ParentTaskStatusGroup, status) : '';

export const filterTaskStatusGroup = (status: StatusFilterGroups): string =>
  status !== StatusFilterGroups.All ? filterEq(ItemFilter.TaskStatusGroup, status) : '';

export const filterTaskStatusGroupOrNull = (status: StatusFilterGroups): string => {
  const taskStatusGroupFilter = filterTaskStatusGroup(status);

  return taskStatusGroupFilter ? filterOr(taskStatusGroupFilter, filterNull(ItemFilter.TaskStatusId, true)) : '';
};

export const filterParentTaskStatusId = (id: number | ReadonlyArray<number>): string =>
  filterIn(ItemFilter.ParentTaskStatusId, id);

// Keeping this incase we need it in the future

const filterTaskStatusSchedulingType = (schedulingType: SchedulingType | Array<SchedulingType>): string =>
  filterIn(ItemFilter.TaskStatusSchedulingType, schedulingType);

export const filterTaskStatusSchedulingTypeOrNull = (schedulingType: SchedulingType | Array<SchedulingType>): string =>
  filterOr(filterTaskStatusSchedulingType(schedulingType), filterNull(ItemFilter.TaskStatusId, true));
