import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Workload } from 'daos/model_types';
import { WidgetDao } from 'daos/widgets';
import { Events, EventScopes } from 'features/common/events/types';
import { useEvents } from 'features/common/events/use_events';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { defaultWorkloadDayRange } from 'features/common/workload/workload_day_range_dropdown';
import { WorkloadTable } from 'features/common/workload/workload_table';
import { WorkloadTopControls } from 'features/common/workload/workload_top_controls';
import useWidgetParams from 'features/dashboards/hooks/use_widget_params';
import { getWidgetForId } from 'features/dashboards/selectors';
import { useDebouncedInput } from 'hooks/use_debounced_input';
import { awaitRequestFinish } from 'lib/api';
import { taskInputQuickFilter } from 'lib/quick_filter_and_search_helpers/quick_filter';
import { purgeWorkloads } from 'state/entities/slice';

import 'features/common/workload/workload_view.scss';
import 'features/common/workload/index.scss';

export function WidgetWorkload() {
  const dispatch = useDispatch();
  const { widgetId } = useWidgetParams();
  const [workloadId, setWorkloadId] = useState<number | null>(null);
  const widget = useSelector((state) => getWidgetForId(state, widgetId));
  const [workloadFetchComplete, setWorkloadsFetchComplete] = useState(false);
  const [shouldExpandAllUsers, setShouldExpandAllUsers] = useState(false);
  const expandAllUsers = () => setShouldExpandAllUsers(true);
  const expandAllUsersAfterQuickFilterChange = !workloadFetchComplete && shouldExpandAllUsers;

  const [dayRange, setDayRange] = useState(defaultWorkloadDayRange);

  const { inputValue, setDebouncedValue } = useDebouncedInput(widgetId);
  const filterQueryString = useMemo(() => {
    return taskInputQuickFilter({ inputValue, includeContainsAncestorName: true, isWorkload: true });
  }, [inputValue]);

  const fetchWorkload = useCallback(() => {
    if (!widget) {
      return;
    }

    const { organizationId, workspaceId, dashboardId } = widget;
    const { uuid } = dispatch(
      WidgetDao.fetchWorkload(
        {
          organizationId: Number(organizationId),
          workspaceId: Number(workspaceId),
          dashboardId,
          widgetId,
        },
        {
          include: {
            includeTasks: true,
          },
          filter: filterQueryString,
          query: { days: dayRange },
        },
      ),
    );

    dispatch(
      awaitRequestFinish<Workload>(uuid, {
        onSuccess: ({ data }) => {
          setWorkloadId(data.id);
        },
        onFinish: () => setWorkloadsFetchComplete(true),
      }),
    );
  }, [dayRange, dispatch, filterQueryString, widget, widgetId]);

  useEffect(() => {
    setWorkloadsFetchComplete(false);
    fetchWorkload();

    return () => {
      setWorkloadsFetchComplete(false);
      dispatch(purgeWorkloads());
    };
  }, [dispatch, fetchWorkload]);

  const handleReschedule = () => {
    fetchWorkload();
  };

  useEvents({
    event: Events.RESCHEDULE_COMPLETE,
    callback: handleReschedule,
    scope: EventScopes.Workload,
  });

  if (!workloadId) {
    return null;
  }

  return (
    <div className="workspace-workload">
      <WorkloadTopControls
        expandAllUsers={expandAllUsers}
        inputValue={inputValue}
        isWidget
        setDebouncedValue={setDebouncedValue}
        dayRange={dayRange}
        setDayRange={setDayRange}
        includePlannedWork={false}
        setIncludePlannedWork={noop}
      />

      <WorkloadTable
        workloadId={workloadId}
        fetchWorkload={fetchWorkload}
        isFiltered={!!inputValue}
        shouldExpandAllUsers={expandAllUsersAfterQuickFilterChange}
        dayRange={dayRange}
      />

      {!workloadFetchComplete && <LpOverlayLoader />}
    </div>
  );
}
