import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export enum SessionStorageKey {
  LeftNavExpanded = 'lp_leftNavExpanded',
  FavoritesExpanded = 'lp_favoritesExpanded',
  DismissedBanner = 'lp_dismissedBanner',
  RedirectLocation = 'lp_redirectLocation',

  ScheduleColumnExpanded = 'lp_schedule_column_expanded',
}

export function getSessionStorageForKey(key: SessionStorageKey) {
  try {
    const sessionStorageValue = sessionStorage.getItem(key);

    if (sessionStorageValue === null) {
      return null;
    }

    return JSON.parse(sessionStorageValue);
    // eslint-disable-next-line unused-imports/no-unused-vars
  } catch (_) {
    return null;
  }
}

function setSessionStorageForKey<T>(key: SessionStorageKey, value: T) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line no-empty, unused-imports/no-unused-vars
  } catch (_) {}
}

export function useSessionState<T>(defaultValue: T, key: SessionStorageKey): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => getSessionStorageForKey(key) ?? defaultValue);

  useEffect(() => setSessionStorageForKey(key, value), [key, value]);

  return [value, setValue];
}
