import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import LpLink from 'containers/shared/lp_link';
import { UserLoginOption } from 'daos/model_types';
import { backend } from 'daos/urls';
import { UserDao } from 'daos/user';
import { AuthType } from 'features/authentication/enums';
import LpErrorMessage from 'features/common/errors/lp_error_message';
import LpFormInput from 'features/common/forms/lp_form_input';
import LpOverlayLoader from 'features/common/loaders/lp_overlay_loader';
import { awaitRequestFinish } from 'lib/api';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { frontend } from 'lib/urls';
import { ProductName } from 'lib/use_product_name';
import { getNexusBaseUrl } from 'state/entities/selectors/system_settings';

const schema = yup.object().shape({
  email: yup.string().trim().email(lpErrorText.email).required(lpErrorText.email),
});

const receiveMessage = (
  nexusBaseUrl: string,
  setIsCheckingSession: Dispatch<SetStateAction<boolean>>,
  event: MessageEvent<{
    hasSession: boolean;
    data?: Array<{
      app: string;
      appExternalId: string;
      organizationId: string;
      userExternalId: string;
      userId: string;
    }>;
    email?: string;
  }>,
) => {
  if (event.origin !== nexusBaseUrl) {
    return;
  }

  const createdIframes = document?.querySelectorAll('[data-iframe-id="iframe-check-session"]');
  if (createdIframes?.length > 0) {
    createdIframes.forEach((iframe) => {
      iframe.remove();
    });
  }
  window.removeEventListener('message', (event) => receiveMessage(nexusBaseUrl, setIsCheckingSession, event), false);

  const { hasSession, data, email } = event.data;

  if (hasSession) {
    const lpEntitlement = data?.find(({ app }) => app === 'lp');
    if (!lpEntitlement) {
      window.location.assign(`${frontend.missingAccount.url({})}?email=${email}`);
    }
  }
  setIsCheckingSession(false);
};

const getLastAccessedOrgId = () => {
  try {
    const localStorageOrgId = localStorage.getItem('organizationId');
    return localStorageOrgId ? Number(localStorageOrgId) : null;
    // eslint-disable-next-line unused-imports/no-unused-vars
  } catch (_) {
    return null;
  }
};

export const EmailForm = ({
  email,
  redirectLocation,
  onLoginOptionsChecked,
}: {
  email: string | undefined;
  redirectLocation: string | undefined;
  onLoginOptionsChecked: (email: string, usingNexusAuth: boolean) => void;
}) => {
  const [isCheckingSession, setIsCheckingSession] = useState(false);
  const dispatch = useDispatch();
  const nexusBaseUrl = useSelector(getNexusBaseUrl);

  useEffect(() => {
    if (nexusBaseUrl) {
      setIsCheckingSession(true);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.style.width = '0px';
      iframe.style.height = '0px';
      iframe.src = `${nexusBaseUrl}/check-session`;
      iframe.dataset.iframeId = 'iframe-check-session';
      document.body.appendChild(iframe);
      window.addEventListener('message', (event) => receiveMessage(nexusBaseUrl, setIsCheckingSession, event));
    }
  }, [nexusBaseUrl]);

  const {
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldMeta,
    getFieldProps,
    setStatus: setFormAPIError,
    status: formAPIError,
  } = useFormik({
    initialValues: {
      email: email || '',
    },
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ email }) => {
      setFormAPIError(undefined);

      const { uuid } = dispatch(
        UserDao.loginOptions({
          email,
          lastAccessedOrgId: getLastAccessedOrgId(),
        }),
      );

      dispatch(
        awaitRequestFinish<UserLoginOption>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              setFormAPIError(errors[0]);
            }

            setSubmitting(false);
          },
          onSuccess: (payload) => {
            const { data } = payload;
            if (data.authType == AuthType.NEXUS) {
              let authUrl = backend.nexusAuth.url({});
              authUrl += email ? `?email=${encodeURIComponent(email)}` : '';
              authUrl += redirectLocation ? `${email ? '&' : '?'}rt=${encodeURIComponent(redirectLocation)}` : '';
              window.location.assign(authUrl);
              return;
            }
            onLoginOptionsChecked(email, false);
            setSubmitting(false);
          },
        }),
      );
    },
  });

  const handleDismissApiError = () => setFormAPIError(undefined);

  if (isCheckingSession) {
    return <LpOverlayLoader />;
  }

  return (
    <Form className="pm-login-sso__form" onSubmit={handleSubmit} loading={isSubmitting}>
      {formAPIError && (
        <LpErrorMessage
          className="login-sso__api-error"
          error={formAPIError}
          customError={
            <>
              <p>Invalid email address.</p>
              <p>Please try again.</p>
            </>
          }
          onDismiss={handleDismissApiError}
        />
      )}

      <LpFormInput
        disableLastPass={false}
        label="Business Email*"
        fieldKey="email"
        getFieldMeta={getFieldMeta}
        getFieldProps={getFieldProps}
        placeholder="Example@business.com"
      />

      <Button
        className="pm-login-sso__form-button pm-login-sso__form-button--full-width"
        type="submit"
        content={'Sign In →'}
        disabled={isSubmitting}
      />
      <div className="form__signup-cta">
        New to <ProductName />
        ?&nbsp;
        <LpLink data-e2e-test-id="sign-up" to={frontend.trial.url({})}>
          Sign Up
        </LpLink>
      </div>
    </Form>
  );
};
