import {
  AccountCircle,
  Chat,
  Dashboard,
  Folder,
  Groups,
  Help,
  Power,
  Settings,
  StarBorder,
  TaskAlt,
} from '@tempo-io/sui-icons';
import Sidebar, {
  APP_KEYS,
  CustomNavigationSection,
  NavigationItem,
  PrimaryNavigationSection,
  SIDEBAR_WIDTH_COLLAPSED,
  SIDEBAR_WIDTH_EXPANDED,
  ScrollableSection,
  UtilityNavigationSection,
} from '@tempo-io/sui-navigation';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { getInAppNotificationsForWorkspaceUserId } from 'features/common/in_app_notifications/selectors';
import { SessionStorageKey, useSessionState } from 'hooks/use_session_state';
import { useWindowResize } from 'hooks/use_window_resize';
import { frontend } from 'lib/urls';
import { getCurrentWorkspaceFavoritesByPriority } from 'state/entities/selectors/favorite';
import { getCurrentWorkspaceUserId } from 'state/entities/selectors/user';

import { FavoritesListItem } from './favorites';
import { tempoApps } from './helpers';
import { useActiveNav, useGetIntegrationsSupported, useHandleNavClick, useToggleSidebarToggler } from './hooks';
import { HelpSupportSubNav, IntegrationsSubNav, MyProfileSubNav, updateSubnavPosition } from './subnavs';

import '@tempo-io/sui-navigation/navigation.css';
import './index.scss';

const integrationsNavId = 'integrations-nav';
const helpAndSupportNavId = 'help-and-support-nav';
const myProfileNavId = 'my-profile-nav';

export const UnifiedLeftNav = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const currentWorkspaceUserId = useSelector(getCurrentWorkspaceUserId);
  const inAppNotifications = useSelector((state) =>
    getInAppNotificationsForWorkspaceUserId(state, currentWorkspaceUserId),
  );
  const hasUnreadDiscussionPosts = !!inAppNotifications?.unreadDiscussionPosts;

  const favorites = useSelector(getCurrentWorkspaceFavoritesByPriority);

  const {
    isIterationsActive,
    isProjectsActive,
    isLibraryActive,
    isMyWorkActive,
    isDiscussionActive,
    isFavoriteActive,
    isPeopleActive,
    isIntegrationsActive,
    isAdministrationActive,
    isHelpActive,
    isMyProfileActive,
  } = useActiveNav();

  const handleNavItemClick = useHandleNavClick();

  const [isSidebarExpanded, setIsSidebarExpanded] = useSessionState(true, SessionStorageKey.LeftNavExpanded);
  const toggleSidebarExpanded = () => {
    setIsSidebarExpanded((prevState) => !prevState);
    setIsIntegrationsExpanded(false);
    setIsHelpExpanded(false);
    setIsProfileExpanded(false);
  };

  const [isFavoritesExpanded, setIsFavoritesExpanded] = useSessionState(true, SessionStorageKey.FavoritesExpanded);
  const toggleFavoritesExpanded = () => setIsFavoritesExpanded((prevState) => !prevState);

  const [isIntegrationsExpanded, setIsIntegrationsExpanded] = useState(false);
  const [isHelpExpanded, setIsHelpExpanded] = useState(false);
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);

  const toggleIntegrationsExpanded = () => {
    setIsIntegrationsExpanded((prevState) => !prevState);
    setIsHelpExpanded(false);
    setIsProfileExpanded(false);
  };
  const toggleHelpExpanded = () => {
    setIsHelpExpanded((prevState) => !prevState);
    setIsIntegrationsExpanded(false);
    setIsProfileExpanded(false);
  };
  const toggleProfileExpanded = () => {
    setIsProfileExpanded((prevState) => !prevState);
    setIsIntegrationsExpanded(false);
    setIsHelpExpanded(false);
  };

  const { showJiraIntegration, showSlackIntegration } = useGetIntegrationsSupported();

  useToggleSidebarToggler(document.querySelector('#portal'));

  useEffect(() => {
    document.body.style.setProperty(
      '--tempo-unified-nav-width',
      isSidebarExpanded ? `${SIDEBAR_WIDTH_EXPANDED}px` : `${SIDEBAR_WIDTH_COLLAPSED}px`,
    );
  }, [isSidebarExpanded]);

  const handleResize = () => {
    const getVisibleSubnav = () => {
      let navElementId,
        subNavElementId = null;
      if (isIntegrationsExpanded) {
        navElementId = 'integrations-nav';
        subNavElementId = 'integrations-subnav';
      }
      if (isHelpExpanded) {
        navElementId = 'help-and-support-nav';
        subNavElementId = 'help-and-support-subnav';
      }
      if (isProfileExpanded) {
        navElementId = 'my-profile-nav';
        subNavElementId = 'my-profile-subnav';
      }

      const navElement = navElementId ? document.getElementById(navElementId) : null;
      const subNavElement = subNavElementId ? document.getElementById(subNavElementId) : null;

      return {
        navElement,
        subNavElement,
        visible: navElement && subNavElement,
      };
    };

    const { navElement, subNavElement, visible } = getVisibleSubnav();

    updateSubnavPosition({
      navElement,
      subNavElement,
      visible: !!visible,
    });
  };
  useWindowResize(handleResize, 0);

  useEffect(() => {
    const subNavElement = document.getElementById('integrations-subnav');
    const navElement = document.getElementById('integrations-nav');

    updateSubnavPosition({
      navElement,
      subNavElement,
      visible: isIntegrationsExpanded,
    });
  }, [isIntegrationsExpanded]);

  useEffect(() => {
    const subNavElement = document.getElementById('help-and-support-subnav');
    const navElement = document.getElementById('help-and-support-nav');

    updateSubnavPosition({
      navElement,
      subNavElement,
      visible: isHelpExpanded,
    });
  }, [isHelpExpanded]);

  useEffect(() => {
    const subNavElement = document.getElementById('my-profile-subnav');
    const navElement = document.getElementById('my-profile-nav');

    updateSubnavPosition({
      navElement,
      subNavElement,
      visible: isProfileExpanded,
    });
  }, [isProfileExpanded]);

  return (
    <div className="unified-nav">
      <Sidebar
        currentAppKey={APP_KEYS.PORTFOLIO_MANAGER}
        isExpanded={isSidebarExpanded}
        onAppSwitchClick={noop}
        onToggleSidebar={toggleSidebarExpanded}
        apps={tempoApps}
      >
        <ScrollableSection>
          <PrimaryNavigationSection>
            <NavigationItem
              active={isIterationsActive}
              href={frontend.iterationsPage.url({ organizationId, workspaceId })}
              icon={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon">
                    <path
                      id="calendar-arrow-up"
                      d="M7 2C7.41563 2 7.75 2.33437 7.75 2.75V4H12.25V2.75C12.25 2.33437 12.5844 2 13 2C13.4156 2 13.75 2.33437 13.75 2.75V4H15C16.1031 4 17 4.89687 17 6V6.5V8V16C17 17.1031 16.1031 18 15 18H5C3.89688 18 3 17.1031 3 16V8V6.5V6C3 4.89687 3.89688 4 5 4H6.25V2.75C6.25 2.33437 6.58437 2 7 2ZM15.5 8H4.5V16C4.5 16.275 4.725 16.5 5 16.5H15C15.275 16.5 15.5 16.275 15.5 16V8ZM9.25 14.75V11.5594L8.03125 12.7781C7.7375 13.0719 7.2625 13.0719 6.97187 12.7781C6.68125 12.4844 6.67812 12.0094 6.97187 11.7188L9.47188 9.21875C9.76562 8.925 10.2406 8.925 10.5312 9.21875L13.0312 11.7188C13.325 12.0125 13.325 12.4875 13.0312 12.7781C12.7375 13.0687 12.2625 13.0719 11.9719 12.7781L10.7531 11.5594V14.75C10.7531 15.1656 10.4188 15.5 10.0031 15.5C9.5875 15.5 9.25313 15.1656 9.25313 14.75H9.25Z"
                      fill="#1D1D1B"
                    />
                  </g>
                </svg>
              }
              onClick={handleNavItemClick}
            >
              Iterations
            </NavigationItem>
            <NavigationItem
              active={isProjectsActive}
              href={frontend.scheduledCollectionPortfolio.url({ organizationId, workspaceId })}
              icon={<Folder />}
              onClick={handleNavItemClick}
            >
              Projects
            </NavigationItem>
            <NavigationItem
              active={isLibraryActive}
              href={frontend.workspaceLibrary.url({ organizationId, workspaceId })}
              icon={<Dashboard />}
              onClick={handleNavItemClick}
            >
              Library
            </NavigationItem>
            <NavigationItem
              active={isMyWorkActive}
              href={frontend.myWork.url({ organizationId, workspaceId })}
              icon={<TaskAlt />}
              onClick={handleNavItemClick}
            >
              My Work
            </NavigationItem>
            <NavigationItem
              active={isDiscussionActive}
              href={frontend.myDiscussions.url({ organizationId, workspaceId })}
              icon={<Chat />}
              onClick={handleNavItemClick}
              indicator={hasUnreadDiscussionPosts ? 'medium' : undefined}
            >
              Discussions
            </NavigationItem>
            {!favorites.length && (
              <NavigationItem
                active={isFavoriteActive}
                href={frontend.favorites.url({ organizationId, workspaceId })}
                icon={<StarBorder />}
                onClick={handleNavItemClick}
              >
                Favorites
              </NavigationItem>
            )}
          </PrimaryNavigationSection>
          <>
            {!!favorites.length && (
              <CustomNavigationSection
                title="Favorites"
                icon={<StarBorder />}
                isExpanded={isFavoritesExpanded}
                onToggleExpanded={toggleFavoritesExpanded}
              >
                {favorites.map((favorite) => (
                  <FavoritesListItem
                    key={favorite.id}
                    favoriteId={favorite.id}
                    handleNavItemClick={handleNavItemClick}
                  />
                ))}
                <NavigationItem
                  active={isFavoriteActive}
                  href={frontend.favorites.url({ organizationId, workspaceId })}
                  onClick={handleNavItemClick}
                >
                  Manage Favorites
                </NavigationItem>
              </CustomNavigationSection>
            )}
          </>
        </ScrollableSection>

        <UtilityNavigationSection>
          <NavigationItem
            active={isAdministrationActive}
            href={frontend.workspaceHub.url({ organizationId, workspaceId })}
            icon={<Settings />}
            onClick={handleNavItemClick}
          >
            Administration
          </NavigationItem>
          <NavigationItem
            active={isPeopleActive && !isMyProfileActive}
            href={frontend.people.url({ organizationId, workspaceId })}
            icon={<Groups />}
            onClick={handleNavItemClick}
          >
            People
          </NavigationItem>
          {(showJiraIntegration || showSlackIntegration) && (
            <NavigationItem
              id={integrationsNavId}
              active={isIntegrationsActive}
              icon={<Power />}
              onClick={toggleIntegrationsExpanded}
            >
              Integrations
              <IntegrationsSubNav
                handleNavItemClick={handleNavItemClick}
                hideSubnav={isIntegrationsExpanded ? () => setIsIntegrationsExpanded(false) : noop}
                navId={integrationsNavId}
                showJiraIntegration={showJiraIntegration}
                showSlackIntegration={showSlackIntegration}
              />
            </NavigationItem>
          )}
          <NavigationItem id={helpAndSupportNavId} active={isHelpActive} icon={<Help />} onClick={toggleHelpExpanded}>
            Help &amp; Support
            <HelpSupportSubNav
              handleNavItemClick={handleNavItemClick}
              hideSubnav={isHelpExpanded ? () => setIsHelpExpanded(false) : noop}
              navId={helpAndSupportNavId}
            />
          </NavigationItem>
          <NavigationItem
            id={myProfileNavId}
            active={isMyProfileActive}
            icon={<AccountCircle />}
            onClick={toggleProfileExpanded}
          >
            My Profile
            <MyProfileSubNav
              handleNavItemClick={handleNavItemClick}
              hideSubnav={isProfileExpanded ? () => setIsProfileExpanded(false) : noop}
              navId={myProfileNavId}
            />
          </NavigationItem>
        </UtilityNavigationSection>
      </Sidebar>
    </div>
  );
};
