import { useSelector } from 'react-redux';

import { useHasTeam25TeamBasedPlanning } from 'hooks/use_has_feature';
import { getItemForId } from 'state/entities/selectors/item';

export const useIsItemPlanned = (itemId: number) => {
  const item = useSelector((state) => getItemForId(state, itemId));

  const hasTeam25TeamBasedPlanning = useHasTeam25TeamBasedPlanning();

  if (!item || !hasTeam25TeamBasedPlanning) {
    return false;
  }

  return item?.teamPlanned ?? false;
};
