import { SyntheticEvent, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getCurrentOrganizationId, getCurrentUserId } from 'features/common/current/selectors';
import { isAdministrationTabActive } from 'features/workspace/helpers';
import { useHasFeature } from 'hooks/use_has_feature';
import { FeatureFlag } from 'lib/feature_flags';
import { getIsSlackIntegrationSupportedByEnvironment } from 'state/entities/selectors/system_settings';

export const useHasUnifiedLeftNav = () => useHasFeature(FeatureFlag.unifiedNavigation);

export const useActiveNav = () => {
  const pathname = useLocation().pathname;
  const currentUserId = useSelector(getCurrentUserId);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  const isIterationsActive = !!pathname.includes('iterations');
  const isProjectsActive =
    !!pathname.includes('projects') ||
    !!pathname.includes('scheduled') ||
    !!pathname.includes('pending') ||
    !!pathname.includes('archive') ||
    !!pathname.includes('template') ||
    !!pathname.includes('capacity') ||
    !!pathname.includes('package') ||
    !!pathname.includes('project');
  const isLibraryActive =
    !isProjectsActive &&
    (!!pathname.includes('library') || !!pathname.includes('dashboard') || !!pathname.includes('report'));
  const isMyWorkActive = !!pathname.includes('my_work');
  const isDiscussionActive = !!pathname.includes('my_discussions');
  const isFavoriteActive = !!pathname.includes('favorites');

  const isPeopleActive = !!pathname.includes('people');
  const isIntegrationsActive = !!pathname.includes('integrations');
  const isHelpActive = !!pathname.includes('academy');
  const isMyProfileActive =
    !!pathname.includes(`people/members/${currentUserId}`) ||
    !!pathname.includes(`people/guests/${currentUserId}`) ||
    !!pathname.includes(`organization/${currentOrganizationId}/hub`);
  const isAdministrationActive = isAdministrationTabActive(pathname) && !isIntegrationsActive && !isMyProfileActive;

  return {
    isIterationsActive,
    isProjectsActive,
    isLibraryActive,
    isMyWorkActive,
    isDiscussionActive,
    isFavoriteActive,
    isPeopleActive,
    isIntegrationsActive,
    isAdministrationActive,
    isHelpActive,
    isMyProfileActive,
  };
};
export const useHandleNavClick = () => {
  const history = useHistory();

  return (event: SyntheticEvent) => {
    const href = event.currentTarget.getAttribute('href');
    if (href) {
      event.preventDefault();
      if (href.startsWith('http')) {
        window.open(href, '_blank');
      } else {
        history.push(href);
      }
    }
  };
};
export const useGetIntegrationsSupported = () => {
  const showJiraIntegration = useHasFeature(FeatureFlag.jiraIntegration);
  const showSlackIntegration = useSelector(getIsSlackIntegrationSupportedByEnvironment);

  return { showJiraIntegration, showSlackIntegration };
};

export const useCloseSubnav = (navId: string, subnavId: string, cb: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !document.getElementById(subnavId)?.contains(event.target as Node) &&
        !document.getElementById(navId)?.contains(event.target as Node)
      ) {
        cb();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cb, navId, subnavId]);
};

export const useToggleSidebarToggler = (targetNode: Element | null) => {
  const observer = useMemo(() => {
    const className = 'hide-sidebar-toggler';

    return new MutationObserver((mutationsList) => {
      if (targetNode) {
        mutationsList.forEach(() => {
          if (targetNode.className.includes('dimmable') && targetNode.className.includes('dimmed')) {
            document.body.classList.add(className);
          } else {
            document.body.classList.remove(className);
          }
        });
      }
    });
  }, [targetNode]);

  useEffect(() => {
    if (targetNode) {
      observer.observe(targetNode, { attributeFilter: ['class'] });
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, targetNode]);
};
