import { request } from 'lib/api';
import { HttpMethod } from 'lib/api/types';
import { TASK_BOARD_DEFAULT_FETCH_LIMIT } from 'lib/constants';

import { ChangesOptions } from './changes';
import { WorkloadSortOrder } from './enums';
import { itemIncludes } from './item';
import { OrderItemFetch } from './item_enums';
import { Widget } from './model_types';
import { defineModel, reduceIncludedOptions } from './shared';
import { ItemIncludes, LocationFilterSettings, WidgetConfig } from './types';
import { backend } from './urls';

const { createBody, deleteBody, updateBody, resource } = defineModel({
  apiType: 'widgets',
  type: 'WIDGET',
});

const { WIDGET } = resource;

export const defaultLocationConfig: LocationFilterSettings = {
  locationFilterItemId: null,
  locationFilterPackageStatus: null,
  projectCreatedByUserId: null,
  projectCustomFieldFilters: null,
  projectNameFilter: null,
  projectScheduleRiskOnly: false,
  projectWorkLimitRiskOnly: false,
  projectStatusFilter: null,
  taskCreatedByUserId: null,
  taskCustomFieldFilters: null,
  taskNameFilter: null,
  taskScheduleDirectiveAsapOnly: false,
  taskScheduleRiskOnly: false,
  taskWorkLimitRiskOnly: false,
  taskStatusFilter: null,
  taskStatusIdsFilter: null,
  groupIdFilter: null,
  organizationUserIdFilter: null,
  projectHasFileFilter: false,
  taskHasFileFilter: false,
  dateRangeFilterType: null,
  dateRangeFilterFrom: null,
  dateRangeFilterTo: null,
  dateRangeIncludeAllOverride: false,
};

export const defaultConfig: WidgetConfig = {
  title: null,
  columns: null,
  content: null,
  customFieldId: null,
  showAlertIcons: true,
  showTotals: false,
  intakeType: null,
  listType: null,
  s3Id: null,
  listPreviewRows: null,
  listPreviewColumns: null,
  onClickView: null,
  onClickViewFieldId: null,
  ...defaultLocationConfig,
};

interface DashboardWidgetsParams {
  dashboardId: number | string;
  organizationId: number | string;
  workspaceId: number | string;
}

interface SingleDashboardWidgetParams extends DashboardWidgetsParams {
  widgetId: number | string;
}

interface ReportWidgetParams {
  organizationId: number | string;
  workspaceId: number | string;
  widgetId: number | string;
}

interface WidgetItemOptions {
  filter?: string;
  include?: ItemIncludes;
  query?: { limit?: number; order?: string; columnHeaders?: string; itemNameHeader?: string };
}

interface WidgetOptions {
  include?: WidgetIncludes;
  filter?: string;
  query?: {
    order?: string;
    limit?: number;
  };
}

interface ListWidgetOptions extends WidgetOptions {
  query?: {
    order?: string;
    limit?: number;
    columnKeys?: string;
  };
}

export interface WidgetIncludes {
  includeDashboard?: boolean;
  includeTemplateItems?: boolean;
  includeParentItems?: boolean;
  includeIntakeForm?: boolean;
  includeLocationFilterItem?: boolean;
  includeLibraryResource?: boolean;
  includeLibraryResourceItem?: boolean;
}

interface WorkloadIncludes {
  includeAncestorItemMetrics?: boolean;
  includeOrganizationUser?: boolean;
  includeTaskAncestors?: boolean;
  includeTasks?: boolean;
}

interface WorkloadOptions {
  filter?: string;
  include?: WorkloadIncludes;
  query?: {
    limit?: number;
    days?: number;
    includeUnscheduledOrgUsers?: boolean;
    order?: WorkloadSortOrder;
  };
}

interface IntakeCreate {
  [key: string]: Array<string>;
}

const widgetIncludes = ({
  includeDashboard,
  includeTemplateItems,
  includeParentItems,
  includeIntakeForm,
  includeLocationFilterItem,
  includeLibraryResource,
  includeLibraryResourceItem,
}: WidgetIncludes = {}) =>
  reduceIncludedOptions([
    includeDashboard && 'dashboard',
    includeTemplateItems && 'intakeForm.templateItems',
    includeTemplateItems && 'intakeForm.templateItems.ancestors',
    includeParentItems && 'intakeForm.parentItems',
    includeParentItems && 'intakeForm.parentItems.ancestors',
    includeIntakeForm && 'intakeForm',
    includeLocationFilterItem && 'locationFilterItem',
    includeLibraryResource && 'libraryResource',
    includeLibraryResourceItem && 'libraryResource.item',
  ]);

const workloadIncludes = ({
  includeAncestorItemMetrics,
  includeOrganizationUser,
  includeTaskAncestors,
  includeTasks,
}: WorkloadIncludes = {}) =>
  reduceIncludedOptions([
    includeAncestorItemMetrics && 'ancestor.itemMetrics',
    includeOrganizationUser && 'organizationUser',
    includeTaskAncestors && 'tasks.ancestors',
    includeTasks && 'tasks',
  ]);

const widgetUpdate = (params: SingleDashboardWidgetParams, widget: Partial<Widget>) =>
  request(backend.widget.url(params), WIDGET, updateBody(Number(params.widgetId), widget));

const intakeWidgetSettingsCreate = (params: SingleDashboardWidgetParams, widgetIntakeBody: any) =>
  request(backend.widgetIntake.url(params), WIDGET, {
    body: JSON.stringify({ data: widgetIntakeBody }),
    method: HttpMethod.POST,
  });

const intakeWidgetFormPost = (params: SingleDashboardWidgetParams, widgetIntakeBody: IntakeCreate) =>
  request(backend.widgetIntakeFormCreate.url(params), WIDGET, {
    body: JSON.stringify({ data: widgetIntakeBody }),
    method: HttpMethod.POST,
  });

const widgetCreate = (params: DashboardWidgetsParams, widget: Partial<Widget>) =>
  request(backend.widgets.url(params), WIDGET, createBody(widget));

const widgetDestroy = (params: SingleDashboardWidgetParams) =>
  request(backend.widget.url(params), WIDGET, deleteBody(Number(params.widgetId)));

const widgetDuplicate = (params: SingleDashboardWidgetParams) =>
  request(backend.widgetDuplicate.url(params), WIDGET, { method: HttpMethod.POST });

const widgetFetch = (params: SingleDashboardWidgetParams, { include }: WidgetOptions = {}) =>
  request(backend.widget.url(params, { include: widgetIncludes(include) }), WIDGET, {
    method: HttpMethod.GET,
  });

const widgetFetchWorkload = (params: SingleDashboardWidgetParams, { filter, include, query }: WorkloadOptions) =>
  request(
    backend.widgetWorkload.url(params, {
      filter,
      include: workloadIncludes(include),
      query,
    }),
    WIDGET,
    {
      method: HttpMethod.GET,
    },
  );

const fetchItemList = (params: SingleDashboardWidgetParams, { filter, query }: ListWidgetOptions) =>
  request(backend.widgetItemList.url(params, { filter, query }), WIDGET, { method: HttpMethod.GET });

const fetchPeopleList = (params: SingleDashboardWidgetParams, { filter, query }: ListWidgetOptions) =>
  request(backend.widgetPeopleList.url(params, { filter, query }), WIDGET, { method: HttpMethod.GET });

const fetchTaskBoard = (params: SingleDashboardWidgetParams, { filter }: WidgetOptions = {}) =>
  request(
    backend.widgetTaskBoard.url(params, {
      filter,
      query: { order: OrderItemFetch.GlobalPriority, limit: TASK_BOARD_DEFAULT_FETCH_LIMIT },
    }),
    WIDGET,
    {
      method: HttpMethod.GET,
    },
  );

const widgetFetchChanges = (params: SingleDashboardWidgetParams, { filter, limit }: ChangesOptions) =>
  request(backend.widgetChanges.url(params, { filter, query: { limit } }), WIDGET, {
    method: HttpMethod.GET,
  });

const widgetFetchMetrics = (params: SingleDashboardWidgetParams) =>
  request(backend.widgetMetrics.url(params), WIDGET, {
    method: HttpMethod.GET,
  });

const fetchItems = (params: SingleDashboardWidgetParams, { filter, include, query }: WidgetItemOptions = {}) =>
  request(backend.widgetItems.url(params, { filter, include: itemIncludes(include), query }), WIDGET, {
    method: HttpMethod.GET,
  });

const fetchReportWidget = (params: ReportWidgetParams, { include }: WidgetOptions = {}) =>
  request(backend.reportWidget.url(params, { include: widgetIncludes(include) }), WIDGET, {
    method: HttpMethod.GET,
  });

const deleteReportWidget = (params: ReportWidgetParams) =>
  request(backend.reportWidget.url(params), WIDGET, deleteBody(Number(params.widgetId)));

const updateReportWidget = (params: ReportWidgetParams, widget: Partial<Widget>) =>
  request(backend.reportWidget.url(params), WIDGET, updateBody(Number(params.widgetId), widget));

const fetchTableReportItemList = (params: ReportWidgetParams, { filter, query }: WidgetOptions = {}) =>
  request(backend.tableReportWidgetItemList.url(params, { filter, query }), WIDGET, { method: HttpMethod.GET });

const fetchTableReportPeopleList = (params: ReportWidgetParams, { filter, query }: WidgetOptions = {}) =>
  request(backend.tableReportWidgetPeopleList.url(params, { filter, query }), WIDGET, { method: HttpMethod.GET });

const exportXlsxURL = (params: SingleDashboardWidgetParams, { filter, query }: WidgetItemOptions = {}) =>
  backend.widgetGridExport.url(params, { filter, query });

const exportPeopleListXlsxURL = (params: SingleDashboardWidgetParams, { filter, query }: WidgetItemOptions = {}) =>
  backend.widgetPeopleListGridExport.url(params, { filter, query });

const exportReportXlsxUrl = (params: ReportWidgetParams, { filter, query }: WidgetItemOptions = {}) =>
  backend.reportWidgetGridExport.url(params, { filter, query });

const exportPeopleReportXlsxUrl = (params: ReportWidgetParams, { filter, query }: WidgetItemOptions = {}) =>
  backend.tableReportWidgetPeopleExcelExport.url(params, { filter, query });

export const WidgetDao = {
  create: widgetCreate,
  destroy: widgetDestroy,
  duplicate: widgetDuplicate,
  exportXlsxURL,
  exportReportXlsxUrl,
  exportPeopleListXlsxURL,
  exportPeopleReportXlsxUrl,
  intakeWidgetSettingsCreate,
  intakeWidgetFormPost,
  fetch: widgetFetch,
  fetchItems,
  update: widgetUpdate,
  updateReportWidget,
  fetchWorkload: widgetFetchWorkload,
  fetchChanges: widgetFetchChanges,
  fetchMetrics: widgetFetchMetrics,
  fetchItemList,
  fetchPeopleList,
  fetchTaskBoard,
  fetchTableReportItemList,
  fetchTableReportPeopleList,
  fetchReportWidget,
  deleteReportWidget,
} as const;
