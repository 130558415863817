import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';

import { FrontendColumns, StandardColumns } from 'containers/shared/custom_column/enum';
import { customColumnDefaultProps } from 'containers/shared/custom_column/helpers';
import { TargetFinishDelta, TargetStartDelta } from 'containers/shared/custom_column/schedule_delta';
import { ItemType } from 'daos/enums';
import { DataGridViewType } from 'features/common/data_grid/enums';
import {
  ScheduleDateColumnFormatter,
  ItemAncestryNameColumnFormatter,
  ColorColumnFormatter,
  HoursColumnFormatter,
  DateColumnFormatter,
  DateRangeColumnFormatter,
  ItemGridSummaryFormatter,
} from 'features/common/data_grid/formatters';
import {
  AssignmentNameColumnFormatter,
  BreadCrumbFormatter,
  DoneColumnFormatter,
  TrackingOnlyFormatter,
} from 'features/common/data_grid/formatters/assignment';
import { ScheduleBarFormatter } from 'features/common/data_grid/formatters/schedule_bar';
import { SortableHeaderCellWithSelectedCount } from 'features/common/data_grid/formatters/select_column';
import {
  ItemCreatedByColumnFormatter,
  ItemRateSheetColumnFormatter,
  ItemUpdatedByColumnFormatter,
  AssignedColumnFormatter,
} from 'features/common/data_grid/formatters/user_columns';
import {
  WorkLimitColumnFormatter,
  WorkLimitDeltaColumnFormatter,
} from 'features/common/data_grid/formatters/work_limit';
import { SortableScheduleBarHeader } from 'features/common/data_grid/header_formatters/headers_';
import {
  ItemFormatterProps,
  ItemSummaryFormatterProps,
  ItemHeaderFormatterProps,
} from 'features/common/data_grid/types';
import { COLUMN_WIDTH_S, TEXT_ALIGN_LEFT } from 'lib/constants';
import { itemTypeToSingularDisplay } from 'lib/display_helpers/item_display_name';

interface AssignmentBreadcrumbProps {
  name: string;
  breadcrumbWidthMultiplier: number;
  buildAncestryUpToItemType: ItemType;
  setEstimateModalAssignmentId: Dispatch<SetStateAction<number | undefined>>;
  dataGridViewType: DataGridViewType;
  showAlertIcons: boolean;
}

export const assignmentNameBreadcrumbColumn = ({
  name,
  breadcrumbWidthMultiplier,
  buildAncestryUpToItemType,
  setEstimateModalAssignmentId,
  dataGridViewType,
  showAlertIcons,
}: AssignmentBreadcrumbProps) => ({
  name,
  key: 'name',
  width: COLUMN_WIDTH_S * breadcrumbWidthMultiplier,
  frozen: true,
  resizable: true,
  sortable: true,
  headerCellClass: classNames('lp-data-grid__name-header', TEXT_ALIGN_LEFT),
  formatter: (props: ItemFormatterProps) => (
    <BreadCrumbFormatter
      buildAncestryUpToItemType={buildAncestryUpToItemType}
      setEstimateModalAssignmentId={setEstimateModalAssignmentId}
      dataGridViewType={dataGridViewType}
      showAlertIcons={showAlertIcons}
      {...props}
    />
  ),
  summaryFormatter: (_props: ItemSummaryFormatterProps) => {
    return <strong>Totals</strong>;
  },
  headerRenderer: SortableHeaderCellWithSelectedCount,
});

export const itemNameBreadcrumbColumn = (name: string, buildAncestryUpToItemType: ItemType, showAlertIcons = true) => ({
  name,
  key: 'name',
  width: COLUMN_WIDTH_S * 4,
  frozen: true,
  resizable: true,
  sortable: true,
  headerCellClass: classNames('lp-data-grid__name-header', TEXT_ALIGN_LEFT),
  formatter: (props: ItemFormatterProps) => (
    <ItemAncestryNameColumnFormatter
      buildAncestryUpToItemType={buildAncestryUpToItemType}
      showAlertIcons={showAlertIcons}
      {...props}
    />
  ),
  summaryFormatter: (_props: ItemSummaryFormatterProps) => {
    return <strong>Totals</strong>;
  },
  headerRenderer: SortableHeaderCellWithSelectedCount,
});

export const itemNameColumn = (itemType: ItemType) => ({
  name: `${itemTypeToSingularDisplay[itemType]} Name`,
  key: 'name',
  width: COLUMN_WIDTH_S * 3,
  resizable: true,
});

export const colorColumn = (itemType: ItemType) => ({
  name: `${itemTypeToSingularDisplay[itemType]} Color`,
  key: 'color',
  width: COLUMN_WIDTH_S,
  resizable: true,
  formatter: ColorColumnFormatter,
});

/**
 * Item Property Columns
 */

export const assignedColumn = {
  ...customColumnDefaultProps(StandardColumns.Assigned),
  sortable: false,
  formatter: AssignedColumnFormatter,
};

export const createdAtColumn = {
  ...customColumnDefaultProps(StandardColumns.CreatedAt),
  formatter: (props: ItemFormatterProps) => <DateColumnFormatter date={props.row[StandardColumns.CreatedAt]} />,
};

export const createdByColumn = {
  ...customColumnDefaultProps(StandardColumns.CreatedBy),
  formatter: ItemCreatedByColumnFormatter,
};

export const itemFileCountColumn = {
  ...customColumnDefaultProps(StandardColumns.ItemFileCount),
  summaryFormatter: (props: ItemSummaryFormatterProps) => <ItemGridSummaryFormatter {...props} />,
};

export const itemBillingRateSheetColumn = {
  ...customColumnDefaultProps(StandardColumns.BillingRateSheet),
  formatter: ItemRateSheetColumnFormatter,
};

export const itemPayRateSheetColumn = {
  ...customColumnDefaultProps(StandardColumns.PayRateSheet),
  formatter: ItemRateSheetColumnFormatter,
};

export const itemIdColumn = customColumnDefaultProps(StandardColumns.ItemId);
export const plannedDeltaColumn = customColumnDefaultProps(StandardColumns.PlannedDelta);
export const plannedEstimateColumn = customColumnDefaultProps(StandardColumns.PlannedEstimate);
export const descriptionColumn = customColumnDefaultProps(StandardColumns.Description);
export const packageNameColumn = customColumnDefaultProps(StandardColumns.Package);
export const packageStatusColumn = customColumnDefaultProps(FrontendColumns.PackageStatus);
export const projectNameColumn = customColumnDefaultProps(StandardColumns.Project);
export const subFolderNameColumn = customColumnDefaultProps(StandardColumns.SubFolder);
export const taskNameColumn = customColumnDefaultProps(StandardColumns.Task);
export const priorityRushColumn = customColumnDefaultProps(StandardColumns.PriorityRush);
export const projectStatusColumn = customColumnDefaultProps(StandardColumns.ProjectStatus);
export const targetFinishTypeColumn = customColumnDefaultProps(StandardColumns.TargetFinishType);
export const taskStatusColumn = customColumnDefaultProps(StandardColumns.TaskStatus);
export const workTypeColumn = customColumnDefaultProps(StandardColumns.WorkType);
export const workingTeamColumn = customColumnDefaultProps(StandardColumns.WorkingTeam);
export const locationColumn = customColumnDefaultProps(StandardColumns.Location);
export const subFolderStatusColumn = customColumnDefaultProps(StandardColumns.FolderStatus);
export const storyPointSchemaNameColumn = customColumnDefaultProps(StandardColumns.StoryPointSchemeName);
export const storyPointColumn = customColumnDefaultProps(StandardColumns.StoryPoint);
export const storyPointCountActiveColumn = customColumnDefaultProps(StandardColumns.StoryPointCountActive);
export const storyPointCountOnHoldColumn = customColumnDefaultProps(StandardColumns.StoryPointCountOnHold);
export const storyPointCountDoneColumn = customColumnDefaultProps(StandardColumns.StoryPointCountDone);

export const folderStatusColumn = (itemType: ItemType) => ({
  ...customColumnDefaultProps(StandardColumns.PriorityRush),
  name: `${itemTypeToSingularDisplay[itemType]} Status`,
});

export const updatedByColumn = {
  ...customColumnDefaultProps(StandardColumns.UpdatedBy),
  formatter: ItemUpdatedByColumnFormatter,
};

export const updatedAtColumn = {
  ...customColumnDefaultProps(StandardColumns.UpdatedAt),
  formatter: (props: ItemFormatterProps) => <DateColumnFormatter date={props.row[StandardColumns.UpdatedAt]} />,
};

export const workLimitColumn = {
  ...customColumnDefaultProps(StandardColumns.WorkLimit),
  formatter: WorkLimitColumnFormatter,
};

export const workLimitDeltaColumn = {
  ...customColumnDefaultProps(StandardColumns.WorkLimitDelta),
  formatter: WorkLimitDeltaColumnFormatter,
};

/**
 * Schedule Data Columns
 */
export const clippedEffortColumn = {
  ...customColumnDefaultProps(StandardColumns.ClippedEffort),
  formatter: (props: ItemFormatterProps) => (
    <HoursColumnFormatter {...props} seconds={props.row[StandardColumns.ClippedEffort]} />
  ),
  summaryFormatter: (props: ItemSummaryFormatterProps) => <ItemGridSummaryFormatter {...props} />,
};

export const doneDateColumn = {
  ...customColumnDefaultProps(StandardColumns.DoneDate),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.DoneDate} />
  ),
};

export const expectedFinishColumn = {
  ...customColumnDefaultProps(StandardColumns.ExpectedFinish),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.ExpectedFinish} />
  ),
};

export const expectedStartColumn = {
  ...customColumnDefaultProps(StandardColumns.ExpectedStart),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.ExpectedStart} />
  ),
};

export const finishRangeColumn = {
  ...customColumnDefaultProps(StandardColumns.FinishRange),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.FinishRange} />
  ),
};

export const latestFinishColumn = {
  ...customColumnDefaultProps(StandardColumns.LatestFinish),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.LatestFinish} />
  ),
};

export const scheduleBarColumn = {
  ...customColumnDefaultProps(StandardColumns.ScheduleBar),
  formatter: (props: ItemFormatterProps) => <ScheduleBarFormatter {...props} />,
  headerRenderer: (props: ItemHeaderFormatterProps) => <SortableScheduleBarHeader {...props} />,
};

export const targetFinishColumn = {
  ...customColumnDefaultProps(StandardColumns.TargetFinish),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.TargetFinish} />
  ),
};

export const targetStartDeltaColumn = {
  ...customColumnDefaultProps(StandardColumns.TargetStartDelta),
  formatter: ({ row }: ItemFormatterProps) => (
    <TargetStartDelta includeAlertIcon itemId={row.id} notApplicableText={''} valueOnly />
  ),
};

export const targetFinishDeltaColumn = {
  ...customColumnDefaultProps(StandardColumns.TargetFinishDelta),
  formatter: ({ row }: ItemFormatterProps) => (
    <TargetFinishDelta includeAlertIcon itemId={row.id} notApplicableText={''} valueOnly />
  ),
};

export const targetStartColumn = {
  ...customColumnDefaultProps(StandardColumns.TargetStart),
  formatter: (props: ItemFormatterProps) => (
    <ScheduleDateColumnFormatter {...props} dateOption={StandardColumns.TargetStart} />
  ),
};

export const dateRangeColumn = {
  ...customColumnDefaultProps(StandardColumns.DateRange),
  formatter: DateRangeColumnFormatter,
};

/**
//  * Assignment Data Columns
 */

export const assignmentNameColumn = {
  ...customColumnDefaultProps(StandardColumns.Assignment),
  formatter: AssignmentNameColumnFormatter,
};

export const assignmentDoneColumn = {
  ...customColumnDefaultProps(StandardColumns.AssignmentDone),
  formatter: DoneColumnFormatter,
};

export const costCodeColumn = customColumnDefaultProps(StandardColumns.CostCode);

export const highEstimateColumn = {
  ...customColumnDefaultProps(StandardColumns.HighEstimate),
  formatter: (props: ItemFormatterProps) => {
    return <HoursColumnFormatter {...props} seconds={props.row[StandardColumns.HighEstimate] ?? 0} />;
  },
};

export const lowEstimateColumn = {
  ...customColumnDefaultProps(StandardColumns.LowEstimate),
  formatter: (props: ItemFormatterProps) => {
    return <HoursColumnFormatter {...props} seconds={props.row[StandardColumns.LowEstimate] ?? 0} />;
  },
};

export const trackingOnlyColumn = {
  ...customColumnDefaultProps(StandardColumns.TrackingOnly),
  formatter: TrackingOnlyFormatter,
};
