import { NavigationItem } from '@tempo-io/sui-navigation';
import { noop } from 'lodash';
import { KeyboardEvent, SyntheticEvent, forwardRef } from 'react';

import { useFavoriteData } from 'features/favorite/hooks/use_favorite_data';

interface FavoritesListItemProps {
  favoriteId: number;
  handleNavItemClick: (event: SyntheticEvent) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
}

export const FavoritesListItem = forwardRef<HTMLAnchorElement, FavoritesListItemProps>(
  ({ favoriteId, handleNavItemClick, onKeyDown = noop }: FavoritesListItemProps, ref) => {
    const { favoriteName, favoriteUrlTo, favoriteColor, favoriteSubSection } = useFavoriteData(favoriteId);

    if (!favoriteName || !favoriteUrlTo) {
      return null;
    }

    return (
      <NavigationItem ref={ref} href={favoriteUrlTo} onClick={handleNavItemClick} onKeyDown={onKeyDown}>
        <div className="favorite-item" style={{ borderLeftColor: `#${favoriteColor}` }}>
          <div className="favorite-item--name">{favoriteName}</div>
          {!!favoriteSubSection && <div className="favorite-item--sub-section">{favoriteSubSection}</div>}
        </div>
      </NavigationItem>
    );
  },
);
